import React, { useContext, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  Button,
  TableFooter,
  TablePagination,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import { generateStateDisplayName } from '../../Utils/genrateDisplayNames';
import Context from '../../Contexts';
import TablePaginationActions from '../TablePaginationActions';
import ConfirmationModal from '../ConfirmationModel';
import { color } from '../../UI/color/colorConfig';

export default function StreamsTable({
  data = [],
  onRowViewClicked = () => console.log('event row clicked'),
  onRowStatusClicked = () => console.log('row button clicked'),
  onChangePage = () => console.log('page change clicked'),
  onRowStartStopClicked = () => console.log('start / stop clicked'),
  onCloneStreamClicked = () => console.log('clone clicked'),
  count = 10,
  rowsPerPage = 12,
  page = 1,
}) {
  const classes = useStyles();
  const [isModelVisible, setIsModelVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const context = useContext(Context);
  const { formConfig } = context;

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Sr No.</TableCell>
            <TableCell>Title</TableCell>
            <TableCell align="center">State</TableCell>
            <TableCell align="center">Stream Key</TableCell>
            <TableCell align="center">Action</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Start/Stop</TableCell>
            <TableCell align="center">Clone</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data &&
            data.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="center">
                    {page * rowsPerPage + index + 1}
                  </TableCell>
                  <TableCell
                    style={{ width: '350px', wordBreak: 'break-word' }}
                    component="th"
                    scope="row"
                  >
                    {`${row.title}`}
                  </TableCell>

                  <TableCell align="center">
                    {generateStateDisplayName(formConfig, row.state)}
                  </TableCell>

                  <TableCell
                    style={{ width: '350px', wordBreak: 'break-word' }}
                    align="center"
                  >
                    {row.stream_key || ''}
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onRowViewClicked(row)}
                    >
                      View
                    </Button>
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      color={row.is_active === 'Y' ? 'primary' : 'secondary'}
                      onClick={() => onRowStatusClicked(row)}
                    >
                      {row.is_active === 'Y' ? 'ENABLED' : 'DISABLED'}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        if (row.is_live && !row.is_complete) {
                          setSelectedRow(row);
                          setIsModelVisible(true);
                          return;
                        }
                        onRowStartStopClicked(row);
                      }}
                      variant="outlined"
                      color={
                        !row.is_live && !row.is_complete
                          ? 'primary'
                          : 'secondary'
                      }
                      disabled={!row.is_live && row.is_complete ? true : false}
                    >
                      {!row.is_live && !row.is_complete
                        ? 'Start'
                        : row.is_live && !row.is_complete
                        ? 'Stop'
                        : 'N/A'}
                    </Button>
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onCloneStreamClicked(row)}
                    >
                      Clone
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rows
              colSpan={12}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={onChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>

      <ConfirmationModal
        open={isModelVisible}
        title={`Stop ${selectedRow?.title}`}
        description={
          <Typography variant="subtitle1">
            Are you sure you want to stop{' '}
            <span style={{ fontWeight: 'bold', color: color.red.main_color }}>
              {selectedRow?.title}
            </span>
          </Typography>
        }
        handleCancel={() => setIsModelVisible(false)}
        handleConfirm={() => {
          onRowStartStopClicked(selectedRow);
          setSelectedRow(null);
          setIsModelVisible(false);
        }}
        confirmText="Yes, Stop"
        askPassword={false}
      />
    </TableContainer>
  );
}
